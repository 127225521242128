<template>
  <div class="folderTreeViewComponent">
    <div class="tree-menu">

      <!-- ERROR MODAL -->
      <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
        <div class="text-center">
          <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
        </div>
      </b-modal>

      <!-- CHOOSE FOLDER -->
      <b-modal ref="chooseActionFolderModal" size="lg" hide-footer>
        <template v-slot:modal-title>
          <strong> Que souhaitez-vous faire ? </strong>
        </template>
        <div>
          <div class="row">
            <div class="col-sm-4 col-12">
              <b-button variant="outline-dark" pill block @click="$refs.chooseActionFolderModal.hide(); $refs.moveFolderModal.show()">
                Déplacer ce dossier
              </b-button>
            </div>
            <div class="col-sm-4 col-12">
              <b-button variant="outline-primary" pill block @click="$refs.chooseActionFolderModal.hide(); $refs.modifyFolderModal.show()">
                Modifier ce dossier
              </b-button>
            </div>
            <div class="col-sm-4 col-12">
              <b-button variant="outline-danger" pill block @click="$refs.chooseActionFolderModal.hide(); $refs.deleteFolderModal.show()">
                Supprimer ce dossier
              </b-button>
            </div>
          </div>
        </div>
      </b-modal>


      <!-- MODIFY FOLDER -->
      <b-modal ref="modifyFolderModal" size="lg" hide-footer>
        <template v-slot:modal-title>
          <strong> Modifier votre dossier </strong>
        </template>
        <div>
          <folderModificationComponent
            :token="token"
            :folder="node"
            v-on:folder-modified="folderModified($event)"
            />
        </div>
      </b-modal>

      <b-modal ref="FolderModifiedModal" id="FolderModifiedModal" size="lg" hide-footer title="Dossier modifié">
        <div class="text-center">
          <p><strong>Votre dossier a bien été modifié ! </strong></p>
          <b-button class="mt-3" block pull variant="outline-dark" @click="$bvModal.hide('FolderModifiedModal')">
            Fermer
          </b-button>
        </div>
      </b-modal>

      <!-- MOVING FOLDER -->
      <b-modal ref="moveFolderModal" size="lg" hide-footer>
        <template v-slot:modal-title>
          <strong> Déplacer votre dossier </strong>
        </template>
        <div>
          <folderMovingComponent
            :token="token"
            :folder="node"
            v-on:folder-moved="$refs.moveFolderModal.hide(); $refs.FolderMovedModal.show(); resetFoldersAndPages()"
            />
        </div>
      </b-modal>

      <b-modal ref="FolderMovedModal" id="FolderMovedModal" size="lg" hide-footer title="Dossier déplacé">
        <div class="text-center">
          <p><strong>Votre dossier a bien été déplacé ! </strong></p>
          <b-button class="mt-3" block pull variant="outline-dark" @click="$bvModal.hide('FolderMovedModal')">
            Fermer
          </b-button>
        </div>
      </b-modal>

      <!-- DELETE FOLDER -->
      <b-modal ref="deleteFolderModal" size="lg" hide-footer>
        <template v-slot:modal-title>
          <strong> Supprimer votre dossier </strong>
        </template>
        <div>
          <folderDeletionComponent
            :token="token"
            :folder="node"
            v-on:folder-deleted="$refs.deleteFolderModal.hide(); $refs.FolderDeletedModal.show(); resetFoldersAndPages()"
            />
        </div>
      </b-modal>

      <b-modal ref="FolderDeletedModal" id="FolderDeletedModal" size="lg" hide-footer title="Dossier supprimé">
        <div class="text-center">
          <p><strong>Votre dossier a bien été supprimé ! </strong></p>
          <b-button class="mt-3" block pull variant="outline-dark" @click="$bvModal.hide('FolderDeletedModal')">
            Fermer
          </b-button>
        </div>
      </b-modal>


      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#F9BF3B">
      </loading>

      <div class="label-wrapper cursor-class">
        <div :style="indent">
          <span class="icon-folder-class" @click="toggleChildren" v-if="!showFolderChildren">📁</span>
          <span class="icon-folder-class" @click="toggleChildren" v-else>📂</span>
          <span @click="$refs.chooseActionFolderModal.show()" class="ml-2 title-folder-class">
            {{ node.name }} <b-badge pill variant="light">{{periodiciteRender(node.periodicite)}}</b-badge>
          </span>
        </div>
      </div>

      <div v-if="showFolderChildren">
        <folderTreeViewComponent
          v-for="folder in childrenFolders"
          v-bind:key="folder.id"
          :depth="depth + 1"
          :node="folder"
          :token="token"
          v-on:reset-folders-pages="$emit('reset-folders-pages')"
          v-on:check-page-box="$emit('check-page-box', $event)"
        >
        </folderTreeViewComponent>
      </div>

      <div v-if="showPageChildren">
        <pageTreeViewComponent
          v-for="page in childrenPages"
          v-bind:key="page.id"
          :depth="depth + 1"
          :node="page"
          :token="token"
          v-on:reset-folders-pages="$emit('reset-folders-pages')"
          v-on:check-page-box="$emit('check-page-box', $event)"
        >
        </pageTreeViewComponent>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import folderModificationComponent from "@/components/folder/folderModificationComponent"
import folderMovingComponent from "@/components/folder/folderMovingComponent"
import folderDeletionComponent from "@/components/folder/folderDeletionComponent"
import pageTreeViewComponent from '@/components/page/pageTreeViewComponent'
import renderMixins from '@/mixins/renderMixins'

import { APIFolder } from '@/api/APIFolder'
import { APIPage } from '@/api/APIPage'

const apiFolder = new APIFolder()
const apiPage = new APIPage()

export default {
  name: 'folderTreeViewComponent',
  props: {
    depth: Number,
    node: Object,
    token: String
  },
  components: {
    Loading,
    folderModificationComponent,
    folderMovingComponent,
    folderDeletionComponent,
    pageTreeViewComponent
  },
  mixins: [
    renderMixins,
  ],
  data() {
     return {
       showFolderChildren: false,
       showPageChildren: false,

       childrenFolders: [],
       isChildrenFoldersLoading: false,

       childrenPages: [],
       isChildrenPagesLoading: false,
     }
  },
  watch: {
    '$route': {
      immediate: true,
      handler: function () {
        const openFolders = this.$route.query.folders
        if (openFolders) {
          if (openFolders[0].includes(this.node.name)) {
            this.getChildrenFolders()
            this.getChildrenPages()
          }
        }
      }
    }
  },
  computed: {
    indent() {
      return { transform: `translate(${this.depth * 50}px)` }
    },
    isLoading () {
      if (this.isChildrenFoldersLoading || this.isChildrenPagesLoading) {
        return true
      }
      return false
    },
  },
  methods: {
    toggleChildren() {
       if (this.showFolderChildren) {
         this.showFolderChildren = !this.showFolderChildren
       }
       else {
         this.getChildrenFolders()
       }
       if (this.showPageChildren) {
         this.showPageChildren = !this.showPageChildren
       }
       else {
         this.getChildrenPages()
       }
    },
    getChildrenFolders() {
      this.isChildrenFoldersLoading = true
      apiFolder.getChildrenFolders(this.token, this.node.id)
      .then((result) => {
        this.childrenFolders = result.data
        this.showFolderChildren = !this.showFolderChildren;
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('treeViewComponent getChildrenFolders API Error : ' + String(error))
      })
      .finally(() => {
        this.isChildrenFoldersLoading = false
      })
    },
    getChildrenPages() {
      this.isChildrenPagesLoading = true
      apiPage.getChildrenPages(this.token, this.node.id)
      .then((result) => {
        this.childrenPages = result.data
        this.showPageChildren = !this.showPageChildren;
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('treeViewComponent getChildrenFolders API Error : ' + String(error))
      })
      .finally(() => {
        this.isChildrenPagesLoading = false
      })
    },

    folderModified (value) {
      this.$refs.modifyFolderModal.hide()
      this.$refs.FolderModifiedModal.show();
      this.node.name = value.name
      this.node.notes = value.notes
      this.node.crawlingHour = value.crawlingHour
      this.node.periodicite = value.periodicite
      this.node.requete = value.requete
    },

    resetFoldersAndPages () {
      this.$emit('reset-folders-pages')
    }
  }
}
</script>

<style>
.cursor-class {
  cursor: pointer;
}

.icon-folder-class:hover {
  font-size: 1.2rem;
}

.title-folder-class:hover {
  font-weight: 600;
}
</style>
