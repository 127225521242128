<template>
  <div class="folderModificationComponent">
    <div class="tree-menu">

      <!-- ERROR MODAL -->
      <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
        <div class="text-center">
          <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
        </div>
      </b-modal>

      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#F9BF3B">
      </loading>


      <b-form v-on:submit.prevent="modifyFolder()">
        <b-form-group id="modify-folder-name-group" label="Nom du dossier" label-for="modify-folder-name-input" class="mandatory-input">
          <b-form-input
            v-model="name" type="text"
            maxlength="250"
            placeholder="Nom du dossier"
            id="modify-folder-name-input"
            @input="$v.name.$touch()"
            :state="$v.name.$dirty ? !$v.name.$error : null">
          </b-form-input>
          <div class="form-error" v-if="$v.name.error">Le nom du dossier doit faire entre 2 et 250 caractères</div>
        </b-form-group>

        <div class="row">
          <div class="col-sm-6 col-12">
            <b-form-group id="modify-folder-periodicite-group"
              label="Périodicité de l'information"
              label-for="modify-folder-periodicite-input"
              class="mandatory-input">
              <b-form-select
                v-model="periodicite"
                id="modify-folder-periodicite-input"
                :options='periodiciteOptions'>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-sm-6 col-12">
            <b-form-group id="modify-folder-crawlingHour-group"
              label="Heure du crawling"
              label-for="modify-folder-crawlingHour-input"
              class="mandatory-input">
              <b-form-select
                v-model="crawlingHour"
                id="modify-folder-crawlingHour-input"
                :options='crawlingHourSelectOptions'>
              </b-form-select>
            </b-form-group>
          </div>
        </div>

        <b-form-group id="modify-folder-requete-group"
          label="Requête associée"
          label-for="modify-folder-requete-input">
          <b-form-select
            v-model="requete"
            id="modify-folder-requete-input"
            :options='requetesSelectOptions'>
          </b-form-select>
        </b-form-group>

        <b-form-group id="modify-folder-notes-group" label="Notes" label-for="modify-folder-notes-input">
          <b-form-textarea
            v-model="notes" type="text"
            placeholder="Notes"
            id="modify-folder-notes-input"
            rows="2"
            maxLength="500"
            @input="$v.notes.$touch()"
            :state="$v.notes.$dirty ? !$v.notes.$error : null">
          </b-form-textarea>
        </b-form-group>

        <b-button
          pill block type="submit" variant="outline-dark" class="mt-2"
          :disabled="isInvalidFolder">
            Modifier votre dossier
        </b-button>
      </b-form>



    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { APIFolder } from '@/api/APIFolder'
import { APIRequete } from '@/api/APIRequete'

const apiFolder = new APIFolder()
const apiRequete = new APIRequete()

export default {
  name: 'folderModificationComponent',
  props: {
    folder: Object,
    token: String
  },
  components: {
    Loading,
  },
  mixins: [
    validationMixin
  ],
  data() {
     return {
       // REQUETES
       requetes: [],
       requetesSelectOptions: [
         {text: 'Aucune', value: null}
       ],
       isLoadingRequetes: false,

       isModifyingFolder: false,
       crawlingHour: null,
       name: '',
       notes: '',
       periodicite: 1,
       requete: null,

       periodiciteOptions: [
        { text: 'Non crawlable', value: 0 },
        { text: 'Quotidienne', value: 1 },
        { text: 'Hebdomadaire', value:  7 },
        { text: 'Mensuelle', value:  30 },
        { text: 'Trimestrielle ou plus', value:  90 },
      ],
     }
  },
  validations: {
    name: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
    notes: {
      maxLength: maxLength(512),
    },
  },
  computed: {
    isLoading () {
      if (this.isLoadingRequetes || this.isModifyingFolder) {
        return true
      }
      return false
    },
    isInvalidFolder () {
      if (this.$v.$invalid) {
        return true
      }
      return false
    },
    crawlingHourSelectOptions () {
      var final_array = [{text: "Aucune", value: null}]
      for (var i = 1; i < 24; i++) {
        final_array.push(
          {
            'text': String(i) + ' heure',
            'value': i,
          }
        )
      }
      return final_array
    }
  },
  watch: {
    requetes: function(newRequetes) {
      if (newRequetes.length == 0) {
        this.requetesSelectOptions = [{text: 'Aucune', value: null}]
      }
      else {
        var final_array = [{text: 'Aucune', value: null}]
        for (var i = 0; i < newRequetes.length; i++) {
          final_array.push(
            {
              'text': newRequetes[i].name,
              'value': newRequetes[i].id,
            }
          )
        }
        this.requetesSelectOptions = final_array
      }
    }
  },
  mounted: function() {
    this.name = this.folder.name
    this.notes = this.folder.notes
    this.crawlingHour = this.folder.crawlingHour
    this.periodicite = this.folder.periodicite
    this.requete = this.folder.requete
    this.getAllRequetes()
  },
  methods: {
    modifyFolder () {
      this.isModifyingFolder = true
      const data = {
        'crawlingHour': this.crawlingHour,
        'name': this.name,
        'notes': this.notes,
        'periodicite': this.periodicite,
      }

      if (this.requete) {
        data['requete'] = this.requete
      }

      apiFolder.modifyFolder(
        this.token, this.folder.id, data)
      .then((result) => {
        this.$emit('folder-modified', result.data)
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('folderModificationComponent modifyFolder API Error : ' + String(error))
      })
      .finally(() => {
        this.isModifyingFolder = false
      })

    },

    getAllRequetes () {
      this.isRequetesLoading = true
      apiRequete.getAllRequetes(this.token)
      .then((result) => {
        this.requetes = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('RequeteList getAllRequetes API Error : ' + String(error))
      })
      .finally(() => {
        this.isRequetesLoading = false
      })
    },

  }
}
</script>
