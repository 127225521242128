<template>
  <div class="pageDeletionComponent">
    <div class="tree-menu">

      <!-- ERROR MODAL -->
      <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
        <div class="text-center">
          <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
        </div>
      </b-modal>

      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#F9BF3B">
      </loading>

      <p>
        Êtes-vous sûr de vouloir supprimer cette page ?
      </p>

      <b-button @click="deletePage" block pill variant="outline-danger">
         Oui, supprimer
      </b-button>

    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { APIPage } from '@/api/APIPage'

const apiPage = new APIPage()

export default {
  name: 'pageDeletionComponent',
  props: {
    page: Object,
    token: String
  },
  components: {
    Loading,
  },
  data() {
     return {
       isDeletingPage: false,
     }
  },
  computed: {
    isLoading () {
      if (this.isDeletingPage) {
        return true
      }
      return false
    },
  },
  methods: {
    deletePage () {
      this.isDeletingPage = true
      apiPage.deletePage(
        this.token, this.page.id)
      .then(() => {
        this.$emit('page-deleted')
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('pageDeletionComponent deletePage API Error : ' + String(error))
      })
      .finally(() => {
        this.isDeletingPage = false
      })
    },
  }
}
</script>
