<template>
  <div class="FolderPageList" >

    <!-- ERROR MODAL -->
    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <!-- CREATE FOLDER -->
    <b-modal ref="createFolderModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Créer un dossier </strong>
      </template>
      <div>
        <folderCreationComponent
          :token="token"
          v-on:folder-created="$refs.createFolderModal.hide(); $refs.FolderCreatedModal.show(); resetFoldersAndPages()"
          v-on:folder-creation-error="$refs.createFolderModal.hide(); $refs.globalAPILoadingErrorModal.show();"
          />
      </div>
    </b-modal>

    <b-modal ref="FolderCreatedModal" id="FolderCreatedModal" size="lg" hide-footer title="Dossier créé">
      <div class="text-center">
        <p><strong>Votre dossier a bien été créé ! </strong></p>

        <b-button class="mt-3" block pull variant="outline-dark" @click="$bvModal.hide('FolderCreatedModal')">
          Fermer
        </b-button>
      </div>
    </b-modal>


    <!-- CREATE PAGE -->
    <b-modal ref="createPageModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Créer une page </strong>
      </template>
      <div>
        <pageCreationComponent
          :token="token"
          v-on:page-created="$refs.createPageModal.hide(); $refs.PageCreatedModal.show(); resetFoldersAndPages()"
          v-on:page-creation-error="$refs.createPageModal.hide(); $refs.globalAPILoadingErrorModal.show();"
          />
      </div>
    </b-modal>

    <!-- CREATE PAGE -->
    <b-modal ref="deletePageModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Supprimer des pages </strong>
      </template>
      <div>
        <p>
          <strong> Nombre de pages à supprimer : </strong> {{pagesSelected.length}}
        </p>
        <p> Êtes-vous sûr de vouloir supprimer les pages sélectionnées ? </p>
        <b-button class="mt-3" block pull variant="outline-danger" @click="deletePages">
          Je confirme et supprime les pages sélectionnées
        </b-button>
      </div>
    </b-modal>

    <b-modal ref="PageCreatedModal" id="PageCreatedModal" size="lg" hide-footer title="Dossier créé">
      <div class="text-center">
        <p><strong>Votre page a bien été créée ! </strong></p>

        <b-button class="mt-3" block pull variant="outline-dark" @click="$bvModal.hide('PageCreatedModal')">
          Fermer
        </b-button>
      </div>
    </b-modal>


    <!-- PAGE PATH -->
    <b-modal ref="pagePathModal" size="xl" hide-footer>
      <template v-slot:modal-title>
        <strong> Rechercher une page </strong>
      </template>
      <div>
        <b-form v-on:submit.prevent="getPagePath()">
          <b-form-group id="seach-page-group" label="Rechercher par nom ou url" label-for="search-page-input" class="mandatory-input">
            <b-form-input
              v-model="search" type="text"
              maxlength="1024"
              placeholder="Tapez le nom de votre page ou l'url"
              id="search-page-name-input"
              @input="$v.search.$touch()"
              :state="$v.search.$dirty ? !$v.search.$error : null">
            </b-form-input>
            <div class="form-error" v-if="$v.search.$error">Votre Recherche doit faire entre 5 et 1000 caractères</div>
          </b-form-group>

          <b-button
            pill block type="submit" size="sm" variant="outline-dark" class="mt-2"
            :disabled="$v.search.$error">
              Rechercher
          </b-button>
        </b-form>
        <p class="mt-2 text-center" v-if="pagePaths.length == 0">
          <i>Aucun résultat</i>
        </p>
        <p class="mt-2" v-else>
          <span 
            @click="openPageTree"
            v-for="path in pagePaths" 
            v-bind:key="path" 
            class="search-page-link"
          >
          --> {{path.join(' / ')}} <br>
          </span>
        </p>

      </div>
    </b-modal>


    <!-- MOVING MULTIPLE PAGES -->
      <b-modal ref="moveMutliplePagesModal" size="lg" hide-footer>
        <template v-slot:modal-title>
          <strong> Déplacer vos pages </strong>
        </template>
        <div>
          <multiplePagesMovingComponent
            :token="token"
            :pages="pagesSelected"
            v-on:pages-moved="$refs.moveMutliplePagesModal.hide(); $refs.MultiplePagesMovedModal.show(); resetFoldersAndPages(); pagesSelected = [];"
            />
        </div>
      </b-modal>

      <b-modal ref="MultiplePagesMovedModal" id="MultiplePagesMovedModal" size="lg" hide-footer title="Page déplacée">
        <div class="text-center">
          <p><strong>Vos pages ont bien été déplacées ! </strong></p>
          <b-button class="mt-3" block pull variant="outline-dark" @click="$bvModal.hide('MultiplePagesMovedModal')">
            Fermer
          </b-button>
        </div>
      </b-modal>


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#F9BF3B">
    </loading>

    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Paramétrage des pages et dossiers</h1>
        <div class="form_div">
          <div class="form_content">
            <div class="row">
              <div class="align-self-center col-12 col-sm-4">
                <strong> Liste des pages et dossiers de Decidcrawl</strong>
              </div>
              <div class="align-self-center col-12 col-sm-8 text-right">
                <b-button 
                  v-if="pagesSelected.length > 0" 
                  size="sm" 
                  class="mg-sm-2 ml-2" 
                  variant="outline-danger" 
                  pill 
                  @click="$refs.deletePageModal.show()"
                >
                  Supprimer ces pages
                </b-button>
                <b-button v-if="pagesSelected.length > 0" size="sm" class="mg-sm-2 ml-2" variant="outline-primary" pill @click="$refs.moveMutliplePagesModal.show()">
                  Déplacer ces pages
                </b-button>
                <b-button size="sm" class="mg-sm-2 ml-2" variant="outline-secondary" pill @click="$refs.pagePathModal.show()">
                  <v-icon name="search"/> Rechercher
                </b-button>

              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 text-right">
                <b-button size="sm" class="mg-sm-2 ml-2" variant="outline-success" pill @click="$refs.createFolderModal.show()">
                  <v-icon name="plus"/> Ajouter un dossier
                </b-button>
                <b-button size="sm" class="ml-sm-2 ml-2" variant="outline-success" pill @click="$refs.createPageModal.show()">
                  <v-icon name="plus"/> Ajouter une page
                </b-button>
              </div>
            </div>

            <folderTreeViewComponent
              v-for="rootFolder in rootFolders"
              v-bind:key="rootFolder.id"
              :depth="0"
              :node="rootFolder"
              :token="token"
              v-on:reset-folders-pages="resetFoldersAndPages()"
              v-on:check-page-box="checkPageBox($event)"
            >
            </folderTreeViewComponent>
            <pageTreeViewComponent
              v-for="rootPage in rootPages"
              v-bind:key="rootPage.id"
              :depth="0"
              :node="rootPage"
              :token="token"
              v-on:check-page-box="checkPageBox($event)"
            >
            </pageTreeViewComponent>

          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import { APIFolder } from '@/api/APIFolder'
import { APIPage } from '@/api/APIPage'
import folderCreationComponent from '@/components/folder/folderCreationComponent'
import folderTreeViewComponent from '@/components/folder/folderTreeViewComponent'
import formTitleComponent from '@/components/formTitleComponent'
import multiplePagesMovingComponent from '@/components/page/multiplePagesMovingComponent'
import pageCreationComponent from '@/components/page/pageCreationComponent'
import pageTreeViewComponent from '@/components/page/pageTreeViewComponent'

const apiFolder = new APIFolder()
const apiPage = new APIPage()

export default {
  name: 'FolderPageList',
  components: {
    folderCreationComponent,
    folderTreeViewComponent,
    formTitleComponent,
    Loading,
    multiplePagesMovingComponent,
    pageCreationComponent,
    pageTreeViewComponent
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      rootFolders: [],
      isRootFoldersLoading: false,

      rootPages: [],
      isRootPagesLoading: false,

      search: '',
      pagePaths: [],
      isPagePathLoading: false,

      pagesSelected: [],
      isPagesMoving: false
    }
  },
  validations: {
    search: {
      minLength: minLength(5),
      maxLength: maxLength(1000),
      required
    },
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isRootFoldersLoading || this.isRootPagesLoading || this.isPagePathLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.getAllRootFolders()
    this.getAllRootPages()
  },
  methods: {
    checkPageBox(value) {
      const index = this.pagesSelected.indexOf(value)
      if (index === -1){
        this.pagesSelected.push(value)
      }
      else {
        this.pagesSelected.splice(index, 1)
      }
    },
    deletePages () {
      this.isRootFoldersLoading = true
      apiPage.deletePages(this.token, this.pagesSelected)
      .then(() => {
        this.resetFoldersAndPages()
        this.$refs.deletePageModal.hide()
        this.$bvToast.toast(`${this.pagesSelected.length} pages supprimées`, {
          title: 'Pages supprimées',
          autoHideDelay: 5000,
          appendToast: false,
          variant: "danger"
        })
        this.pagesSelected = []
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('FolderPageList deletePages API Error : ' + String(error))
      })
      .finally(() => {
        this.isRootFoldersLoading = false
      })
    },
    resetFoldersAndPages() {
      this.rootFolders = []
      this.rootPages = []
      this.getAllRootFolders()
      this.getAllRootPages()
    },
    // ------------- GETTERS ------------------
    getAllRootFolders () {
      this.isRootFoldersLoading = true
      apiFolder.getAllRootFolders(this.token)
      .then((result) => {
        this.rootFolders = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('FolderPageList getAllRootFolders API Error : ' + String(error))
      })
      .finally(() => {
        this.isRootFoldersLoading = false
      })
    },
    getAllRootPages () {
      this.isRootPagesLoading = true
      apiPage.getAllRootPages(this.token)
      .then((result) => {
        this.rootPages = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('FolderPageList getAllRootPages API Error : ' + String(error))
      })
      .finally(() => {
        this.isRootPagesLoading = false
      })
    },

    getPagePath () {
      this.isPagePathLoading = true
      apiPage.getPagePath(this.token, this.search)
      .then((result) => {
        this.pagePaths = result.data['paths']
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('FolderPageList getPagePaths API Error : ' + String(error))
      })
      .finally(() => {
        this.isPagePathLoading = false
      })
    },

    openPageTree () {
      this.$router.push({
        path: this.$route.path,
        query: {folders: this.pagePaths},
        replace: true
      })
      this.$refs.pagePathModal.hide()
    }
  }
}
</script>

<style scoped>
.search-page-link {
  cursor: pointer;
  color: #0000FF;
}
.search-page-link:hover {
  font-weight:bold;
}
</style>