<template>
  <div class="pageModificationComponent">
    <div class="tree-menu">

      <!-- ERROR MODAL -->
      <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
        <div class="text-center">
          <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
        </div>
      </b-modal>

      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#F9BF3B">
      </loading>


      <b-form v-on:submit.prevent="modifyPage()">
        <b-form-group id="modify-page-name-group" label="Nom de la page" label-for="modify-page-name-input" class="mandatory-input">
          <b-form-input
            v-model="name" type="text"
            maxlength="250"
            placeholder="Nom de la page"
            id="modify-page-name-input"
            @input="$v.name.$touch()"
            :state="$v.name.$dirty ? !$v.name.$error : null">
          </b-form-input>
          <div class="form-error" v-if="$v.name.error">Le nom de la page doit faire entre 2 et 250 caractères</div>
        </b-form-group>


        <div class="row">
          <div class="col-sm-6 col-12">
            <b-form-group id="modify-page-url-group" label="Url de la page" label-for="modify-page-url-input" class="mandatory-input">
              <b-form-input
                v-model="url" type="text"
                maxlength="1024"
                placeholder="Url de la page"
                id="modify-page-url-input"
                @change="checkIfDuplicatePagesByUrl()"
                @input="$v.url.$touch(); hasCheckedPageUrlDuplicate=false;"
                :state="$v.url.$dirty ? !$v.url.$error : null">
              </b-form-input>
              <div class="form-error" v-if="$v.url.error">L'url doit faire entre 2 et 1024 caractères</div>
              <div v-else>
                <small v-if="hasCheckedPageUrlDuplicate && isExistingPageUrl" class="text-danger">
                  {{ url }} est déjà attribué à une page. Veuillez choisir un autre URL
                </small>
                <small v-if="hasCheckedPageUrlDuplicate && !isExistingPageUrl" class="text-success">
                  {{ url }} est disponible et non attribué à une page existante
                </small>
              </div>
            </b-form-group>
          </div>
          <div class="col-sm-6 col-12">
            <b-form-group id="modify-page-profondeur-group"
              label="Profondeur"
              label-for="modify-page-profondeur-input"
              class="mandatory-input">
              <b-form-select
                v-model="profondeur"
                id="modify-page-profondeur-input"
                :options='profondeurSelectOptions'>
              </b-form-select>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 col-12">
            <b-form-group id="modify-page-zone_analysed-group" label="Zone analysée" label-for="modify-page-zone_analysed-input">
              <b-form-input
                v-model="zoneAnalysed" type="text"
                maxlength="250"
                placeholder="XPath de la zone analysée"
                id="modify-page-zone_analysed-input"
                @input="$v.zoneAnalysed.$touch()"
                :state="$v.zoneAnalysed.$dirty ? !$v.zoneAnalysed.$error : null">
              </b-form-input>
              <div class="form-error" v-if="$v.zoneAnalysed.error">La zone doit faire entre 2 et 250 caractères</div>
            </b-form-group>
          </div>
          <div class="col-sm-6 col-12">
            <b-form-group id="modify-page-zone_crawled-group" label="Zone crawlée" label-for="modify-page-zone_crawled-input">
              <b-form-input
                v-model="zoneCrawled" type="text"
                maxlength="250"
                placeholder="XPath de la zone crawlée"
                id="modify-page-zone_crawled-input"
                @input="$v.zoneCrawled.$touch()"
                :state="$v.zoneCrawled.$dirty ? !$v.zoneCrawled.$error : null">
              </b-form-input>
              <div class="form-error" v-if="$v.zoneCrawled.error">La zone doit faire entre 2 et 250 caractères</div>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-form-group 
              id="create-page-zone_excluded_from_analysis-group" 
              label="Zones excludes de l'analyse" 
              label-for="create-page-zone_excluded_from_analysis-input"
            >
              <b-form-input
                v-model="zoneExcludedFromAnalysis" type="text"
                maxlength="250"
                placeholder="XPath de la zone analysée"
                id="create-page-zone_excluded_from_analysis-input"
                @input="$v.zoneExcludedFromAnalysis.$touch()"
                :state="$v.zoneExcludedFromAnalysis.$dirty ? !$v.zoneExcludedFromAnalysis.$error : null">
              </b-form-input>
              <div class="form-error" v-if="$v.zoneExcludedFromAnalysis.error">La zone doit faire entre 2 et 250 caractères</div>
              <small class="text-info">
                Vous pouvez renseigner plusieurs xpath séparés par des , <br>
                Attention, le xpath est RELATIF par rapport à la zone analysée. Par exemple si vous voulez 
                exclure toutes les balises &lt;p&gt; de votre zone analyse, mettez simplement //p
              </small>
            </b-form-group>
          </div>
        </div>

        <b-form-group id="modify-page-is_all_domains-group" label-for="modify-page-is_all_domains-input">
          <b-form-checkbox
            v-model="isAllDomains"
            id="modify-page-is_all_domains-input">
              Tous les domaines
          </b-form-checkbox>
        </b-form-group>

        <b-form-group id="modify-page-is_modif_annuelle-group" label-for="modify-page-is_modif_annuelle-input">
          <b-form-checkbox
            v-model="isModifAnnuelle"
            id="modify-page-is_modif_annuelle-input">
              Change chaque année
          </b-form-checkbox>
        </b-form-group>

        <b-form-group id="modify-page-forceSelenium-group" label-for="modify-page-forceSelenium-input">
          <b-form-checkbox
            v-model="forceSelenium"
            id="modify-page-forceSelenium-input">
              Force Selenium
          </b-form-checkbox>
          <small class="text-info">
            Cette case vous permet de forcer l'utilisation de Selenium (navigateur émulé) pour le scrapping de <u>la page mère</u>.
            Cette fonctionnalité est utile quand vous souhaitez traiter avec du <strong><u>javascript</u></strong>
          </small>
        </b-form-group>

        <b-form-group id="modify-page-forceSeleniumForFilles-group" label-for="modify-page-forceSeleniumForFilles-input">
          <b-form-checkbox
            v-model="forceSeleniumForFilles"
            id="modify-page-forceSeleniumForFilles-input">
              Force Selenium pour les pages filles
          </b-form-checkbox>
          <small class="text-info">
            Cette case vous permet de forcer l'utilisation de Selenium également pour les <u>pages filles.</u>
            Cette fonctionnalité est utile quand vous souhaitez traiter avec du <strong><u>javascript. </u></strong><br>
            <u>Attention ! Ne fonctionnera pas avec les pdfs</u>
          </small>
        </b-form-group>

        <b-form-group id="modify-page-xpaths_clicked-group" label="Xpath click" label-for="modify-page-xpaths_clicked-input">
          <b-form-input
            v-model="xpathsClicked" type="text"
            maxlength="250"
            placeholder="XPath sur lequel cliquer en arrivant sur la page"
            id="modify-page-xpaths_clicked-input"
            @input="$v.xpathsClicked.$touch()"
            :state="$v.xpathsClicked.$dirty ? !$v.xpathsClicked.$error : null">
          </b-form-input>
          <div class="form-error" v-if="$v.xpathsClicked.error">La zone doit faire entre 2 et 250 caractères</div>
          <small class="text-info">
            Rentrez le xpath sur lequel vous souhaitez cliquer en arrivant sur la page. Cette information est nécessaire si certaines
            informations sont cachées et qu'il faut cliquer sur un bouton pour y accéder. <strong>Attention</strong>, tous les
            éléments répondant à ce Xpath click seront cliqués. <strong>Attention</strong>, veillez à cocher "Force selenium" pour
            forcer l'utilisation du navigateur et ainsi pouvoir cliquer.
          </small>
        </b-form-group>

        <b-form-group id="modify-page-urlForFileRoot-group" label="Url spécifique de root pour les liens" label-for="modify-page-urlForFileRoot-input">
          <b-form-input
            v-model="urlForFileRoot" type="text"
            placeholder="Urls de root"
            id="modify-page-urlForFileRoot-input"
            rows="1"
            maxLength="1024"
            @input="$v.urlForFileRoot.$touch()"
            :state="$v.urlForFileRoot.$dirty ? !$v.urlForFileRoot.$error : null">
          </b-form-input>
          <small class="text-info"> Si l'url de la page est http://test.fr/test1/test2 et que l'url spécifique de root des liens des http://test.fr
            alors tous les liens filles seront ouverts à partir de https://test.fr (exemple : si test.pdf est trouvé dans la page
            il sera ouvert sur http://test.fr/test.pdf et non  http://test.fr/test1/test2/test.pdf)
          </small>
        </b-form-group>

        <b-form-group id="modify-page-urlsExclude-group" label="Urls à exclure" label-for="modify-page-urlsExclude-input">
          <b-form-textarea
            v-model="urlsExclude" type="text"
            placeholder="Urls d'exclusion"
            id="modify-page-urlsExclude-input"
            rows="1"
            maxLength="250"
            @input="$v.urlsExclude.$touch()"
            :state="$v.urlsExclude.$dirty ? !$v.urlsExclude.$error : null">
          </b-form-textarea>
        </b-form-group>

        <b-form-group id="modify-page-urlsInclude-group" label="Urls à inclure" label-for="modify-page-urlsInclude-input">
          <b-form-textarea
            v-model="urlsInclude" type="text"
            placeholder="Urls d'inclusion"
            id="modify-page-urlsInclude-input"
            rows="1"
            maxLength="250"
            @input="$v.urlsInclude.$touch()"
            :state="$v.urlsInclude.$dirty ? !$v.urlsInclude.$error : null">
          </b-form-textarea>
        </b-form-group>

        <b-form-group id="modify-page-notes-group" label="Notes" label-for="modify-page-notes-input">
          <b-form-textarea
            v-model="notes" type="text"
            placeholder="Notes"
            id="modify-page-notes-input"
            rows="2"
            maxLength="500"
            @input="$v.notes.$touch()"
            :state="$v.notes.$dirty ? !$v.notes.$error : null">
          </b-form-textarea>
        </b-form-group>

        <b-button
          pill block type="submit" variant="outline-dark" class="mt-2"
          :disabled="isInvalidPage">
            Modifier votre page
        </b-button>
      </b-form>



    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { APIPage } from '@/api/APIPage'

const apiPage = new APIPage()

export default {
  name: 'pageModificationComponent',
  props: {
    page: Object,
    token: String
  },
  components: {
    Loading,
  },
  mixins: [
    validationMixin
  ],
  data() {
     return {

       isModifyingPage: false,

       forceSelenium: false,
       forceSeleniumForFilles: false,
       isAllDomains: false,
       isModifAnnuelle: false,
       name: '',
       notes: '',
       profondeur: 1,
       url: '',
       urlForFileRoot: '',
       urlsExclude: '',
       urlsInclude: '',
       xpathsClicked: '',
       zoneAnalysed: '',
       zoneExcludedFromAnalysis: '',
       zoneCrawled: '',

       profondeurSelectOptions: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },

      ],

      isCheckingExistingPageUrl: false,
      isExistingPageUrl: false,
      hasCheckedPageUrlDuplicate: false,
     }
  },
  validations: {
    name: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
    notes: {
      minLength: minLength(2),
      maxLength: maxLength(500),
    },
    url: {
      minLength: minLength(2),
      maxLength: maxLength(1024),
      required
    },
    urlForFileRoot: {
      minLength: minLength(2),
      maxLength: maxLength(1024),
    },
    urlsExclude: {
      minLength: minLength(2),
      maxLength: maxLength(250),
    },
    urlsInclude: {
      minLength: minLength(2),
      maxLength: maxLength(250),
    },
    xpathsClicked: {
      minLength: minLength(2),
      maxLength: maxLength(250),
    },
    zoneAnalysed: {
      minLength: minLength(2),
      maxLength: maxLength(250),
    },
    zoneExcludedFromAnalysis: {
      minLength: minLength(2),
      maxLength: maxLength(250),
    },
    zoneCrawled: {
      minLength: minLength(2),
      maxLength: maxLength(250),
    },
  },
  computed: {
    isLoading () {
      if (this.isMovingPage || this.isCheckingExistingPageUrl) {
        return true
      }
      return false
    },
    isInvalidPage () {
      if (this.$v.name.$invalid || this.$v.notes.$invalid || this.$v.url.$invalid || this.$v.urlForFileRoot.$invalid ||
        this.$v.urlsExclude.$invalid || this.$v.urlsInclude.$invalid || this.$v.xpathsClicked.$invalid ||
        this.$v.zoneAnalysed.$invalid || this.$v.zoneExcludedFromAnalysis.$invalid || 
        this.$v.zoneCrawled.$invalid || this.isExistingPageUrl) {
        return true
      }
      return false
    },
  },
  mounted: function() {
    this.forceSelenium = this.page.force_selenium
    this.forceSeleniumForFilles = this.page.force_selenium_for_filles
    this.isAllDomains = this.page.is_all_domains
    this.isModifAnnuelle = this.page.is_modif_annuelle
    this.name = this.page.name
    this.notes = this.page.notes
    this.profondeur = this.page.profondeur
    this.url = this.page.url
    this.urlForFileRoot = this.page.url_for_file_root
    this.urlsExclude = this.page.urls_exclude
    this.urlsInclude = this.page.urls_include
    this.xpathsClicked = this.page.xpaths_clicked
    this.zoneAnalysed = this.page.zone_analysed
    this.zoneExcludedFromAnalysis = this.page.zone_excluded_from_analysis
    this.zoneCrawled = this.page.zone_crawled
  },
  methods: {
    modifyPage () {
      this.isModifyingPage = true
      apiPage.modifyPage(
        this.token, this.page.id, this.forceSelenium, this.forceSeleniumForFilles, this.isAllDomains, this.isModifAnnuelle, this.name, this.notes,
        this.profondeur, this.url, this.urlForFileRoot, this.urlsExclude, this.urlsInclude, this.xpathsClicked, 
        this.zoneAnalysed, this.zoneExcludedFromAnalysis, this.zoneCrawled)
      .then((result) => {
        this.$emit('page-modified', result.data)
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('pageModificationComponent modifyPage API Error : ' + String(error))
      })
      .finally(() => {
        this.isModifyingPage = false
      })

    },

    checkIfDuplicatePagesByUrl () {
      if (this.$v.url.$invalid) {
        this.isExistingPageUrl = false
        this.hasCheckedPageUrlDuplicate = false
        return null
      }
      else {
        this.isCheckingExistingPageUrl = true
        apiPage.getNbPageByUrl(this.token, this.url, this.page.id)
          .then((result) => {
            if (result.data.nb_pages == 0) {
              this.isExistingPageUrl = false
            }
            else {
              this.isExistingPageUrl = true
            }
          })
          .finally(() => {
            this.isCheckingExistingPageUrl = false
            this.hasCheckedPageUrlDuplicate = true
          })
      }
    },

  }
}
</script>
