<template>
  <div class="pageTreeViewComponent">
    <div class="tree-menu">

      <!-- CHOOSE PAGE -->
      <b-modal ref="chooseActionPageModal" size="lg" hide-footer>
        <template v-slot:modal-title>
          <strong> Que souhaitez-vous faire ? </strong>
        </template>
        <div>
          <div class="row">
            <div class="col-sm-4 col-12">
              <b-button variant="outline-dark" pill block @click="$refs.chooseActionPageModal.hide(); $refs.movePageModal.show()">
                Déplacer cette page
              </b-button>
            </div>
            <div class="col-sm-4 col-12">
              <b-button variant="outline-primary" pill block @click="$refs.chooseActionPageModal.hide(); $refs.modifyPageModal.show()">
                Modifier cette page
              </b-button>
            </div>
            <div class="col-sm-4 col-12">
              <b-button variant="outline-danger" pill block @click="$refs.chooseActionPageModal.hide(); $refs.deletePageModal.show()">
                Supprimer cette page
              </b-button>
            </div>
          </div>
        </div>
      </b-modal>

      <!-- DELETE PAGE -->
      <b-modal ref="deletePageModal" size="lg" hide-footer>
        <template v-slot:modal-title>
          <strong> Supprimer cette page </strong>
        </template>
        <div>
          <pageDeletionComponent
            :token="token"
            :page="node"
            v-on:page-deleted="$refs.deletePageModal.hide(); $refs.PageDeletedModal.show(); resetFoldersAndPages()"
            />
        </div>
      </b-modal>

      <b-modal ref="PageDeletedModal" id="PageDeletedModal" size="lg" hide-footer title="Dossier supprimé">
        <div class="text-center">
          <p><strong>Votre page a bien été supprimée ! </strong></p>
          <b-button class="mt-3" block pull variant="outline-dark" @click="$bvModal.hide('PageDeletedModal')">
            Fermer
          </b-button>
        </div>
      </b-modal>

      <!-- MODIFY PAGE -->
      <b-modal ref="modifyPageModal" size="lg" hide-footer>
        <template v-slot:modal-title>
          <strong> Modifier votre page </strong>
        </template>
        <div>
          <pageModificationComponent
            :token="token"
            :page="node"
            v-on:page-modified="pageModified($event)"
            />
        </div>
      </b-modal>

      <b-modal ref="PageModifiedModal" id="PageModifiedModal" size="lg" hide-footer title="Page modifiée">
        <div class="text-center">
          <p><strong>Votre page a bien été modifiée ! </strong></p>
          <b-button class="mt-3" block pull variant="outline-dark" @click="$bvModal.hide('PageModifiedModal')">
            Fermer
          </b-button>
        </div>
      </b-modal>

      <!-- MOVING PAGE -->
      <b-modal ref="movePageModal" size="lg" hide-footer>
        <template v-slot:modal-title>
          <strong> Déplacer votre page </strong>
        </template>
        <div>
          <pageMovingComponent
            :token="token"
            :page="node"
            v-on:page-moved="$refs.movePageModal.hide(); $refs.PageMovedModal.show(); resetFoldersAndPages()"
            />
        </div>
      </b-modal>

      <b-modal ref="PageMovedModal" id="PageMovedModal" size="lg" hide-footer title="Page déplacée">
        <div class="text-center">
          <p><strong>Votre page a bien été déplacée ! </strong></p>
          <b-button class="mt-3" block pull variant="outline-dark" @click="$bvModal.hide('PageMovedModal')">
            Fermer
          </b-button>
        </div>
      </b-modal>

      <div class="label-wrapper cursor-class">
        <div :style="indent">
          <b-form-checkbox
            @input="checkPageBox()"
            >
            📝
            <span @click="$refs.chooseActionPageModal.show()" class="ml-2 title-page-class">
              {{ node.name }}
              <b-badge v-if="node.is_modif_annuelle" pill variant="warning"><v-icon scale="0.6" name="sync"/></b-badge>
              <b-badge v-if="node.force_selenium" variant="info"><v-icon scale="1" name="tv"/></b-badge>
              <b-badge v-if="node.is_bugged" class="ml-2" pill variant="danger">Bug</b-badge>
              <b-badge v-if="node.is_bugged" class="ml-2" pill variant="danger">{{node.last_status}}</b-badge>
            </span>
          </b-form-checkbox>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageDeletionComponent from "@/components/page/pageDeletionComponent"
import pageModificationComponent from "@/components/page/pageModificationComponent"
import pageMovingComponent from "@/components/page/pageMovingComponent"

export default {
  name: 'pageTreeViewComponent',
  props: {
    depth: Number,
    node: Object,
    token: String
  },
  components: {
    pageDeletionComponent,
    pageModificationComponent,
    pageMovingComponent
  },
  computed: {
    indent() {
      return { transform: `translate(${this.depth * 50}px)` }
    },
  },
  methods: {
    checkPageBox () {
      this.$emit('check-page-box', this.node.id)
    },
    pageModified (value) {
      this.$refs.modifyPageModal.hide()
      this.$refs.PageModifiedModal.show();
      this.node.is_all_domains = value.is_all_domains
      this.node.is_modif_annuelle = value.is_modif_annuelle
      this.node.name = value.name
      this.node.notes = value.notes
      this.node.profondeur = value.profondeur
      this.node.url = value.url
      this.node.urls_exclude = value.urls_exclude
      this.node.urls_include = value.urls_include
      this.node.zone_analysed = value.zone_analysed
      this.node.zone_excluded_from_analysis = value.zone_excluded_from_analysis
      this.node.zone_crawled = value.zone_crawled
      this.node.url_for_file_root = value.url_for_file_root
      this.node.xpaths_clicked = value.xpaths_clicked

    },
    resetFoldersAndPages () {
      this.$emit('reset-folders-pages')
    },
  }
}
</script>

<style>
.cursor-class {
  cursor: pointer;
}

.title-page-class:hover {
  font-weight: 600;
}
</style>
