<template>
  <div class="folderMovingComponent">
    <div class="tree-menu">

      <!-- ERROR MODAL -->
      <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
        <div class="text-center">
          <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
        </div>
      </b-modal>

      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#F9BF3B">
      </loading>


      <b-form v-on:submit.prevent="moveFolder()">

        <b-form-group id="move-folder-parent-group"
          label="Dossier parent"
          label-for="move-folder-parent-input">
            <vue-bootstrap-typeahead
              ref="parentFilterAutocomplete"
              v-model="parentFilterName"
              :data="parentsFilter"
              :serializer="item => item.name"
              @hit="parentObject = $event"
               placeholder="Rentrez le nom du dossier parent ou laissez vide"
            />
        </b-form-group>

        <b-button
          pill block type="submit" variant="outline-dark" class="mt-2">
            Déplacer votre dossier
        </b-button>
      </b-form>

    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import { APIFolder } from '@/api/APIFolder'

const apiFolder = new APIFolder()

export default {
  name: 'folderMovingComponent',
  props: {
    folder: Object,
    token: String
  },
  components: {
    Loading,
    VueBootstrapTypeahead
  },
  data() {
     return {

       isMovingFolder: false,
       parent: null,
       parentsFilter: [],
       parentFilterName: '',
       parentObject: null,
     }
  },
  computed: {
    isLoading () {
      if (this.isMovingFolder) {
        return true
      }
      return false
    },
  },
  watch: {
    parentFilterName(newQuery) {
      if (newQuery == '') {
        this.parentsFilter = []
        this.parentObject = null
      }
      else if (newQuery.length >= 3) {
        apiFolder.searchFoldersByName(this.token, newQuery)
        .then((res) => {
          this.parentsFilter = res.data
        })
      }
    },
  },
  methods: {
    moveFolder () {
      this.isMovingFolder = true
      apiFolder.moveFolder(
        this.token, this.folder.id, this.parentObject)
      .then(() => {
        this.$emit('folder-moved')
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('RequeteCreation createRequete API Error : ' + String(error))
      })
      .finally(() => {
        this.isMovingFolder = false
      })
    },
  }
}
</script>
