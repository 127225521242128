<template>
  <div class="multiplePagesMovingComponent">
    <div class="tree-menu">

      <!-- ERROR MODAL -->
      <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
        <div class="text-center">
          <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
        </div>
      </b-modal>

      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#F9BF3B">
      </loading>


      <b-form v-on:submit.prevent="moveMultiplePages()">
        <strong> Nombre de pages à déplacer : </strong> {{pages.length}}
        <b-form-group id="move-page-folder-group"
          label="Dossier folder"
          label-for="move-page-folder-input">
            <vue-bootstrap-typeahead
              ref="folderFilterAutocomplete"
              v-model="folderFilterName"
              :data="foldersFilter"
              :serializer="item => item.name"
              @hit="folderObject = $event"
               placeholder="Rentrez le nom du dossier parent"
            />
        </b-form-group>

        <b-form-checkbox
          id="isSure-multiple-pages-1"
          v-model="isSure"
          name="isSure-multiple-pages-1"
          :value="true"
          :unchecked-value="false"
        >
          Je suis sûr de vouloir déplacer ces pages
        </b-form-checkbox>
        <b-button
          v-if="isSure"
          pill 
          block 
          type="submit" 
          variant="outline-dark" 
          class="mt-2"
        >
            Déplacer vos pages
        </b-button>
      </b-form>

    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import { APIFolder } from '@/api/APIFolder'
import { APIPage } from '@/api/APIPage'

const apiFolder = new APIFolder()
const apiPage = new APIPage()

export default {
  name: 'multiplePagesMovingComponent',
  props: {
    pages: Array,
    token: String
  },
  components: {
    Loading,
    VueBootstrapTypeahead
  },
  data() {
     return {
       isMovingPages: false,
       folder: null,
       foldersFilter: [],
       folderFilterName: '',
       folderObject: null,
       isSure: false,
     }
  },
  computed: {
    isLoading () {
      if (this.isMovingPages) {
        return true
      }
      return false
    },
  },
  watch: {
    folderFilterName(newQuery) {
      if (newQuery == '') {
        this.foldersFilter = []
        this.folderObject = null
      }
      else if (newQuery.length >= 3) {
        apiFolder.searchFoldersByName(this.token, newQuery)
        .then((res) => {
          this.foldersFilter = res.data
        })
      }
    },
  },
  methods: {
    moveMultiplePages () {
      this.isMovingPages = true
      apiPage.moveMultiplePages(
        this.token, this.pages, this.folderObject)
      .then(() => {
        this.$emit('pages-moved')
      })
      .catch((error) => {
        this.$emit('page-moving-error')
        throw new Error('PageMovingComponent movePage API Error : ' + String(error))
      })
      .finally(() => {
        this.isMovingPages = false
      })
    },
  }
}
</script>
