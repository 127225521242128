import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/login')
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/login')
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIRequete {

  createRequete (token, name, inclusion, second_part_inclusion, exclusion) {

    const url = apiBaseUrl + `/create-requete`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'name': name,
      'inclusion': inclusion,
      'second_part_inclusion': second_part_inclusion,
      'exclusion': exclusion
    }

    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  getAllRequetes(token) {
    const url = apiBaseUrl + `/requetes`
    var headers = {
      'Authorization': 'Beare r '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyRequete (token, id, name, inclusion, second_part_inclusion, exclusion) {

    const url = apiBaseUrl + `/requete/` + String(id)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'name': name,
      'inclusion': inclusion,
      'second_part_inclusion': second_part_inclusion,
      'exclusion': exclusion,
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  deleteRequete (token, id) {

    const url = apiBaseUrl + `/requete/` + String(id)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  testRequete (token, id, text) {
    const url = apiBaseUrl + '/test-requete/' + String(id)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      text: text
    }
    return axios.post(
      url,
      data,
      {'headers': headers}
    )
  }
}
